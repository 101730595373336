  .menu {
    width: 400px;
  }

  .target-span {
    width: 190px !important;
    font-size: 14px;
    font-weight: 500;

    @media (min-width: 1024px) {
      width: 195px !important;
    }

    @media (min-width: 1300px) {
      width: 205px !important;
    }
  
    @media (min-width: 1440px) {
      width: 230px !important;
    }
  }

  .target-time {
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    gap: 5px; 
    margin-left: -10px;
    position: relative;

    @media (min-width: 1300px) {
      gap: 70px;
    }
  }

  .target-icon-time{
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .target-span-time{
    margin-left: 10px;
    position: absolute;
    bottom: -13px;

    @media (min-width: 1300px) {
      bottom: 0;
      margin-bottom: 6px;
      margin-left: 35px;
      margin-right: 5px;
    }
  }

  .target-icon {
    width: 24px;
    height: 24px;
  }

  .option-icon {
    width: 28px;
    height: 28px;
  }

  .target-infos {
    margin-left: 16px;
  }

  .itens-title {
    font-size: 20px;
  }

  .itens-title-icon {
    width: 28px;
    height: 28px;
  }

  .target-menu-title {
    font-size: 30px;
  }

  .monitoramento {
    font-size: 24px;
  }

  .monitoramento-div {
    padding: 36px 30px;
  }

  @media (max-height:  899px) {
    .menu {
      width: 250px;
    }

    .target-span {
      width: 140px;
      font-size: 14px;
    }

    .target-icon {
      width: 16px;
      height: 16px;
    }

    .option-icon {
      width: 24px;
      height: 24px;
    }

    .target-infos {
      margin-left: 0px;
    }

    .itens-title {
      font-size: 16px;
    }

    .itens-title-icon {
      width: 26px;
      height: 26px;
    }

    .target-menu-title {
      font-size: 22px;
    }

    .monitoramento {
      font-size: 18px;
    }

    .monitoramento-div {
      padding: 36px 15px;
    }
  }
