  .alerts-text {
    font-size: 14px;
  }

  .notif-card {
   gap: 4px;
  }

  @media (max-height:  899px) {
    .alerts-text {
      font-size: 12px;
    }

    .notif-card {
      gap: 1px;
     }
  }
