/* Range bar*/
input[type='range'] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100px;
}
input[type='range']:focus {
    outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: black;
    border-radius: 1px;
    border: none;
}
input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
    background: black;
}
input[type='range']::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: black;
    border-radius: 1px;
    border: none;
}
input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input[type='range']::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type='range']::-ms-fill-lower {
    background: black;
    border: 0px solid #737373;
    border-radius: 2px;
    box-shadow: none;
}
input[type='range']::-ms-fill-upper {
    background: black;
    border: 0px solid #737373;
    border-radius: 2px;
    box-shadow: none;
}
input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
    background: black;
}
input[type='range']:focus::-ms-fill-upper {
    background: black;
}
