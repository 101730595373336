  .infos-panel-div {
    gap: 8px;
    cursor: grab;  
  }

  .infos-panel-content-topic {
    font-size: 14px;
    font-family: 'Segoe UI', 'Roboto', 'sans-serif', 'El Messiri';
    color: #1B1B1B;
    font-weight: 700;
}

  .infos-panel-content-item {
    font-size: 13px;
    font-family: 'Segoe UI', 'Roboto', 'sans-serif', 'El Messiri';
    color: #1B1B1B;
    font-weight: 400;
}

  .square {
    height: 20px;
    width: 20px;
    border: 1px solid black;
    margin-right: 5px;
  }

  @media (max-height:  899px) {
    .infos-panel-div  {
        gap: 2px;
    }

    .infos-panel-content-topic {
        font-size: 12px;
        font-family: 'Segoe UI', 'Roboto', 'sans-serif', 'El Messiri';
        color: #1B1B1B;
        font-weight: 700;
      }

      .infos-panel-content-item {
        font-size: 12px;
        font-family: 'Segoe UI', 'Roboto', 'sans-serif', 'El Messiri';
        color: #1B1B1B;
        font-weight: 400,
      }
  }
