.mapboxgl-popup > .mapboxgl-popup-content{
    background-color: rgba(255, 255, 255, 1);
    padding: 0 0 0;    
    border-radius: 16px;
    position: relative;
    width: 60vw;
}
.mapboxgl-popup-tip{
    display: none !important;
}

.mapboxgl-popup-close-button {
    display: none;
    color: black;
    font-size: large;
}
.popup{
    display: grid;
    background-color: transparent;
    color: black;
    width: 100%;
    height: 100%;
}
.popup-item2-div{
    height: 24px;
    margin-left: 96px;
}
.popup-tabs{
    display: flex;
    gap: 2px;
    width: 100%;
}
.popup-select-tab{
    background-color: transparent;
    border: 1px solid black;
    color:black;
    border-radius: 0px 4px 0 0;
    padding: 2px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.popup-select-tab:hover{
    background-color: rgba(120, 120, 120, 0.7);
}
.popup-tab{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
}
.popup-div{
    border-top: 1px black solid;
    padding: 8px 0 4px;
}
.popup-item-div{
    height: 24px;
    margin-left: 148px;
}
.popup-item-div2{
    height: 24px;
    margin-left: 75px;
}
.overflowText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 800px;
}
.popup-mult-item-div{
    margin: 2px 0 2px 0;
    border-bottom: 1px solid;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    white-space:nowrap;
    overflow: hidden;
}

.popup-mult-item-div-overflow{
    margin: 2px 0 2px 0;
    border-bottom: 1px solid;
    width: 100%;
    height: 30px;
    display: inline-block;
    justify-content: flex-start;
    margin-bottom: 10px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popup-mult-title-div{
    left: 13%;
    position: fixed;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
    .popup-item-div{
        height: 24px;
        margin-left: 87px;
    }
    .popup-item2-div{
        height: 24px;
        margin-left: 56px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .popup-item-div{
        height: 24px;
        margin-left: 105px;
    }
    .popup-item2-div{
        height: 24px;
        margin-left: 68px;
    }
}
