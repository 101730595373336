#form_style{
    background: rgb(2,0,36);
    background: linear-gradient(145deg, rgba(2,0,36,1) 0%, rgba(2,48,71,1) 35%, rgba(24,127,155,1) 100%);
    min-width: 708px;
}

#fontLabel{
    color:white!important;

}

#account-email{
    color:white!important;
    width: 444px!important;
}
#account-password{
    color:white!important;
    width: 444px!important;
}

/* Cor de fundo do autocomplete */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px rgba(2,0,36,1) inset !important;
    -webkit-text-fill-color: white !important;
  }

.size_text{
    width: 444px!important;
}