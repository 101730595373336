.MonitoringPage{
    width: 100%;
    height: 100vh;
    margin: auto;
    overflow: hidden;
}

.homepage-sidebar-button{
    z-index: 1;
    position: fixed;
    top: 16px;
    left: 16px;
    padding: 12px 20px;
    border-radius: 4px;
    background-color: whitesmoke;
    border: 1px solid black;
    transition: 0.2s ease;
}

.homepage-sidebar-button:hover{
    transform: scale(1.1);
}

.map{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.mapboxgl-control-container{
  visibility: hidden;
}

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right {
  position: absolute;
  pointer-events: none;
  z-index: 0 !important;

}
