.measureDiv{
    position:inherit;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    padding: 8px;
    background-color: white;
    color: black;
    font-size: 16px;
    border-radius: 8px;
}